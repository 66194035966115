<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="方案名称" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-input v-model="queryData.name" placeholder="请输入方案名称" />
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="客户名称" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select v-model="queryData.customerIdList" mode="multiple" style="width: 100%" placeholder="请选择客户"
                  :default-value="null" option-label-prop="label">
                  <a-select-option :value="item.name" :label="item.name" v-for=" (item, index) in customerData"
                    :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="客户经理" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select v-model="queryData.staffId" style="width: 100%" mode="multiple" placeholder="请选择客户经理" :default-value="null" :filter-option="filterOption" 
                  option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>


            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="方案状态" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.status" style="width: 100%" placeholder="请选择方案状态" option-label-prop="label">
                  <a-select-option value="1" label="草稿">
                    草稿
                  </a-select-option>
                  <a-select-option value="2" label="已生成">
                    已生成
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="产品类型" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.type" placeholder="请选择类型" option-label-prop="label">
                  <a-select-option value="3" label="提货券">提货券</a-select-option>
                  <a-select-option value="4" label="货堆">货堆</a-select-option>
                  <a-select-option value="5" label="宅配">宅配</a-select-option>
                  <a-select-option value="6" label="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>


          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="成交状态" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.dealStatus" style="width: 100%" placeholder="请选择成交状态"
                  option-label-prop="label">
                  <a-select-option value="1" label="已发送待确认">
                    已发送待确认
                  </a-select-option>
                  <a-select-option value="2" label="未成交">
                    未成交
                  </a-select-option>
                  <a-select-option value="3" label="已成交">
                    已成交
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item  label="创建时间" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :ranges="{
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }" @change="dateChange" :value="dateValue" />
                </a-row>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">

              <a-form-item label="产品售价" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-col :span="11">
                    <a-input v-model="queryData.minProductPrice" placeholder="最小" />
                  </a-col>
                  <a-col :span="2" style="text-align:center">
                    -
                  </a-col>
                  <a-col :span="11">
                    <a-input v-model="queryData.maxProductPrice" placeholder="最大" />
                  </a-col>
                </a-row>
              </a-form-item>

            </a-col>


            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="产品预算" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-col :span="11">
                    <a-input v-model="queryData.minProductBudget" placeholder="最小" />
                  </a-col>
                  <a-col :span="2" style="text-align:center">
                    -
                  </a-col>
                  <a-col :span="11">
                    <a-input v-model="queryData.maxProductBudget" placeholder="最大" />
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <span class="btn-box" style="margin-top: 6px; display: inline-block;">
                <a-col :md="12" :sm="20">
                  <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
                </a-col>
                <a-col :md="12" :sm="20">
                  <a-button type="primary" @click="getData">查询</a-button>
                </a-col>
              </span>
            </a-col>
          </a-row>



        </div>

      </a-form>

      <a-row>
        <a-button @click="visible = true; form = {}" type="primary" v-if="hasPermission('SAVE_PROGRAMME')">创建新方案</a-button>
      </a-row>
    </div>

    <!-- 创建 -->
    <a-modal title="创建方案" :visible="visible" :confirm-loading="confirmLoading" :maskClosable="false" @ok="handleOk"
      :footer="null" @cancel="visible = false" :width="800">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-form-model-item prop="customerId" label="客户" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="form.customerId" style="width: 100%" placeholder="请选择客户" show-search :default-value="null"
            option-label-prop="label">
            <a-select-option :value="item.name" :label="item.name" v-for="item in customerData" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>


        <a-form-model-item prop="type" label="产品类型" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="form.type" style="width: 100%" placeholder="请选择产品类型">
            <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in prodType" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="productPrice" prop="productPrice" label="产品售价" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="form.productPrice" placeholder="产品售价/组"
              @blur="() => { $refs.productPrice.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>


        <a-form-model-item ref="productBudget" prop="productBudget" label="产品预算" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="form.productBudget" placeholder="产品预算/组"
              @blur="() => { $refs.productBudget.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>


        <a-form-model-item ref="businessExpenses" prop="businessExpenses" label="合计业务支出" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="form.businessExpenses" placeholder="合计业务支出"
              @blur="() => { $refs.businessExpenses.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>


        <a-form-model-item label="采购数量" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }"
          ref="purchaseQuantity" prop="purchaseQuantity">
          <div style="display: flex;position: relative;">
            <a-input v-model="form.purchaseQuantity" placeholder="采购数量"
              @blur="() => { $refs.purchaseQuantity.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">份</span>
          </div>
        </a-form-model-item>


        <a-form-model-item required ref="expectedProcurementTime" prop="expectedProcurementTime" label="预计采购时间"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker style="width: 100%;" @change="creDateChange" placeholder="预计采购时间" />
        </a-form-model-item>


        <a-form-item label="备注" prop="remarks" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-textarea v-model="form.remarks" @blur="() => { $refs.remarks.onFieldBlur(); }" placeholder="备注" :rows="4" />
        </a-form-item>
      </a-form-model>

      <div style="display: flex;justify-content: center;">
        <a-button type="primary" @click="handleOk" style="margin-right: 10px;">存草稿</a-button>
        <a-button type="primary" @click="ToDetail">立即选品</a-button>
      </div>
    </a-modal>

    <!-- 修改 -->
    <a-modal title="修改方案" :visible="EditVisible" :confirm-loading="confirmLoading" @ok="handleEdit" :maskClosable="false"
      :closable="false" @cancel="handleEditCanel" :width="700">
      <a-form-model ref="editRuleForm" :model="editObj" :rules="editRules" :label-col="labelCol"
        :wrapper-col="wrapperCol">

        <a-form-model-item prop="customerId" label="客户名称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="editObj.customerId" style="width: 100%" placeholder="请选择客户" :default-value="null"
            option-label-prop="label">
            <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>


        <a-form-model-item prop="type" label="产品类型" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="editObj.type" style="width: 100%" placeholder="请选择产品类型">
            <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in prodType" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="productPrice" prop="productPrice" label="产品售价" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="editObj.productPrice" placeholder="产品售价/组"
              @blur="() => { $refs.productPrice.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>


        <a-form-model-item ref="productBudget" prop="productBudget" label="产品预算" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="editObj.productBudget" placeholder="产品预算/组"
              @blur="() => { $refs.productBudget.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>



        <a-form-model-item ref="businessExpenses" prop="businessExpenses" label="合计业务支出" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="editObj.businessExpenses" placeholder="合计业务支出"
              @blur="() => { $refs.businessExpenses.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>



        <a-form-model-item ref="purchaseQuantity" prop="purchaseQuantity" label="采购数量" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="editObj.purchaseQuantity" placeholder="采购数量"
              @blur="() => { $refs.purchaseQuantity.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">份</span>
          </div>
        </a-form-model-item>

        <a-form-model-item required ref="expectedProcurementTime" prop="expectedProcurementTime" label="预计采购时间"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker style="width: 100%;" v-model="editObj.expectedProcurementTime" @change="editDateChange"
            placeholder="预计采购时间" />
        </a-form-model-item>


        <a-form-item label="备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-textarea v-model="editObj.remarks" @blur="() => { $refs.remarks.onFieldBlur(); }" placeholder="备注"
            :rows="4" />
        </a-form-item>



      </a-form-model>
    </a-modal>

    <!-- 复制 -->
    <a-modal title="复制方案" :visible="copyVisible" :confirm-loading="confirmLoading" @ok="handleCopy" :maskClosable="false"
      :closable="false" @cancel="copyVisible = false" :width="700">
      <a-form-model ref="copyRuleForm" :model="copyObj" :rules="copyRules" :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item prop="customerId" label="客户名称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="copyObj.customerId" style="width: 100%" placeholder="请选择客户" :default-value="null"
            option-label-prop="label">
            <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="type" label="产品类型" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="copyObj.type" style="width: 100%" placeholder="请选择产品类型">
            <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in prodType" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="productPrice" prop="productPrice" label="产品售价" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="copyObj.productPrice" placeholder="产品售价/组"
              @blur="() => { $refs.productPrice.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>
        <a-form-model-item ref="productBudget" prop="productBudget" label="产品预算" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="copyObj.productBudget" placeholder="产品预算/组"
              @blur="() => { $refs.productBudget.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>



        <a-form-model-item ref="businessExpenses" prop="businessExpenses" label="合计业务支出" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="copyObj.businessExpenses" placeholder="合计业务支出"
              @blur="() => { $refs.businessExpenses.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">元</span>
          </div>
        </a-form-model-item>



        <a-form-model-item ref="purchaseQuantity" prop="purchaseQuantity" label="采购数量" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="copyObj.purchaseQuantity" placeholder="采购数量"
              @blur="() => { $refs.purchaseQuantity.onFieldBlur(); }" />
            <span style="position: absolute;right: 10px;top: -2px;">份</span>
          </div>
        </a-form-model-item>

        <a-form-model-item required ref="expectedProcurementTime" prop="expectedProcurementTime" label="预计采购时间"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker style="width: 100%;" @change="copyDateChange" placeholder="预计采购时间" />
        </a-form-model-item>


        <a-form-item label="备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-textarea v-model="copyObj.remarks" @blur="() => { $refs.remarks.onFieldBlur(); }" placeholder="备注"
            :rows="4" />
        </a-form-item>



      </a-form-model>
    </a-modal>


    <!-- 导出 -->
    <a-modal title="导出方案" :visible="exportVisible" :confirm-loading="confirmLoading" @ok="handleExport"
      @cancel="exportVisible = false" :width="700">
      <div style="font-size: 16px; font-weight: 500;margin-bottom: 5px;">产品基本信息</div>
      <a-checkbox-group @change="selectExportChange" :default-value="exportSelectArr">
        <div v-for="(item, index) in exportSelect" :key="index" style="margin-bottom: 10px;">
          <a-checkbox :value="index">
            {{ item.describe }}
          </a-checkbox>
        </div>
      </a-checkbox-group>

      <div style="height: 1px; border-top: 2px dashed #ececec;margin-bottom: 15px;"></div>
      <a-checkbox default-checked disabled /> Excel
      <div style="height: 1px; border-top: 2px dashed #ececec;margin-top: 15px;"></div>

    </a-modal>

    <!-- 选择成交状态 -->
    <a-modal title="产品方案成交状态" :visible="dealVisible" centered @ok="handleDeal" @cancel="dealVisible = false">
      <a-form-item label="" :labelCol="{ span: 3 }" :wrapperCol="{ span: 18, offset: 1 }">
        <a-select v-model="dealStatus" style="width: 100%" placeholder="请选择成交状态" option-label-prop="label">
          <a-select-option value="1" label="已发送待确认">
            已发送待确认
          </a-select-option>
          <a-select-option value="2" label="未成交">
            未成交
          </a-select-option>
          <a-select-option value="3" label="已成交">
            已成交
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>


    <div style="margin-top: 20px;" v-if="hasPermission('PROGRAMME_LIST')">
      <div style="margin-bottom: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total>0 ? 1 : 0 }} 至 {{ queryData.size > total ?  total : queryData.size }} 项结果，共 {{ total }} 项</div>
      <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" :pagination="false"
        :scroll="{ x: 1500 }">
        <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
        <template slot="expectedProcurementTime" slot-scope="text">
          <span v-if="text">{{ formatDate(text) }}</span>
          <span v-else>-</span>
        </template>
        <template slot="staffName" slot-scope="text">
          <p v-if="text">{{ text }}</p>
          <p v-else>-</p>
        </template>

        <template slot="name" slot-scope="text">
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
            {{ text }}
          </a-popover>
        </template>
        <template slot="customerName" slot-scope="text">
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
            {{ text }}
          </a-popover>
        </template>


        <template slot="action" slot-scope="record">
          <div style="padding-left: 12px;">
            <div style="text-align: left;margin-bottom: 2px;">
              <a @click="editChange(record)" v-if="hasPermission('UPDATE_PROGRAMME') && record.isBelong">编辑</a>
              <a style="margin:0 5px" v-if="hasPermission('EXPORT_PROGRAMME')" @click="exportChange(record.id)">导出</a>
              <a @click="details(record.id)" v-if="hasPermission('PROGRAMME_INFO')">产品</a>
            </div>
            <div style="text-align: left;">
              <a @click="progDeal(record)" v-if="hasPermission('UPDATE_PROGRAMME_STATE') && record.isBelong">成交</a>
              <a style="margin:0 5px" v-if="hasPermission('COPY_PROGRAMME')" @click="copyProg(record)">复制</a>
              <a-popconfirm style="cursor: pointer;" title="您确定要删除吗？" ok-text="是" cancel-text="否"
                @confirm="delConfirm(record)" v-if="hasPermission('DEL_PROGRAMME') && record.isBelong">删除</a-popconfirm>
            </div>
          </div>
          <!-- <a-dropdown :trigger="['click','hover']">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item @click="details(record.id)">产品</a-menu-item>
              <a-menu-item @click="progDeal(record)">成交</a-menu-item>
              <a-menu-item @click="copyProg(record)">复制</a-menu-item>
              <a-menu-item>
                <a-popconfirm title="您确定要删除吗？" ok-text="是" cancel-text="否" @confirm="delConfirm(record)">删除</a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size" @change="handlePageChange" />
      </div>
    </div>
    <div  style="text-align: center;font-size: 20px;margin-top: 100px;" v-if="!hasPermission('PROGRAMME_LIST')">
      <a-empty :description="false" />
      无权限查看
    </div>
  </a-card>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center',
    width: 120,
    // ellipsis:true
    // sorter: true
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    scopedSlots: { customRender: 'customerName' },
    ellipsis: true,
    align: 'center',
    width: 150

  },
  {
    title: '产品方案名称',
    dataIndex: 'name',
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'name' },
    width: 200
  },
  {
    title: '类型',
    dataIndex: 'typeName',
    align: 'center',
    width: 100
  },
  {
    title: '采购数量',
    dataIndex: 'purchaseQuantity',
    align: 'center',
    width: 100
  },
  {
    title: '产品预算/组',
    dataIndex: 'productBudget',
    align: 'center',
    width: 100
  },
  {
    title: '产品售价',
    dataIndex: 'productPrice',
    scopedSlots: { customRender: 'productPrice' },
    align: 'center',
    width: 100
  },
  {
    title: '产品方案状态',
    dataIndex: 'statusName',
    scopedSlots: { customRender: 'statusName' },
    align: 'center',
    width: 120
  },
  {
    title: '成交状态',
    dataIndex: 'dealStatusName',
    scopedSlots: { customRender: 'dealStatus' },
    align: 'center',
    width: 100
  },
  {
    title: '预计采购时间',
    dataIndex: 'expectedProcurementTime',
    scopedSlots: { customRender: 'expectedProcurementTime' },
    align: 'center',
    width: 120
  },
  {
    title: '客户经理',
    dataIndex: 'staffId',
    scopedSlots: { customRender: 'staffId' },
    align: 'center',
    width: 80
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 160
  }
]
export default {
  name: 'QueryList',
  // components: { StandardTable },
  data() {
    return {
      iptmd: 4,
      iptsm: 22,
      exportSelect: [{ arrangeName: "price", describe: "一件代发" }, { arrangeName: "jcPrice", describe: "集采价" }, { arrangeName: "plfPrice", describe: "优加售卖价" }, { arrangeName: "marketPrice", describe: "主流电商价" }, { arrangeName: "thirdUrl", describe: "主流电商短链接" }, { arrangeName: "qirPic", describe: "质检报告" }, { arrangeName: "supplierId", describe: "供应商ID" }],
      exportSelectArr: [0, 1, 2, 3, 4, 5, 6],//需要导出项
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      selectedRows: [],
      total: 0,
      value: ['china'],

      // 筛选
      queryData: {
        page: 0,
        size: 10,
        name: '',//方案名称
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
        // staffId:'',
        // dealStatus: '',//成交状态
        // type: '',//
      },
      currentPage: 1,
      // 创建表单
      form: {
        customerId: [],
        type: [],
        productPrice: '',
        productBudget: '',
        businessExpenses: '',
        purchaseQuantity: '',
        expectedProcurementTime: '',
        remarks: ''
      },
      dateValue:"",
      // 修改表单
      editObj: {},
      prodType: [{
        id: 3,
        name: '提货券'
      }, {
        id: 4,
        name: '货堆'
      },
      {
        id: 5,
        name: '宅配'
      },
      {
        id: 6,
        name: '其他'
      },

      ],
      // 导出
      exportVisible: false,
      exportIds: [],

      // 新建方案
      visible: false,
      confirmLoading: false,
      // 修改方案
      EditVisible: false,
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },

      // 复制
      copyVisible: false,
      copyObj: {},

      // 选择成交弹窗
      dealVisible: false,
      dealStatus: [],//成交状态

      rules: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        type: [{ required: true, message: '请选择产品类型', trigger: 'change', }],
        productPrice: [{ required: true, message: '请输入产品售价', trigger: 'blur' }],
        productBudget: [{ required: true, message: '请输入产品预售', trigger: 'blur' }],
        businessExpenses: [{ required: true, message: '请输入合计业务支出', trigger: 'blur' }],
        purchaseQuantity: [{ required: true, message: '请输入采购数量', trigger: 'blur' }],
        expectedProcurementTime: [{ required: true, message: '请选择预计采购时间', trigger: 'change' }],
        remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      editRules: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        type: [{ required: true, message: '请选择产品类型', trigger: 'change', }],
        productPrice: [{ required: true, message: '请输入产品售价', trigger: 'blur' }],
        productBudget: [{ required: true, message: '请输入产品预售', trigger: 'blur' }],
        businessExpenses: [{ required: true, message: '请输入合计业务支出', trigger: 'blur' }],
        purchaseQuantity: [{ required: true, message: '请输入采购数量', trigger: 'blur' }],
        expectedProcurementTime: [{ required: true, message: '请选择预计采购时间', trigger: 'change' }],
        remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      copyRules: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        type: [{ required: true, message: '请选择产品类型', trigger: 'change', }],
        productPrice: [{ required: true, message: '请输入产品售价', trigger: 'blur' }],
        productBudget: [{ required: true, message: '请输入产品预售', trigger: 'blur' }],
        businessExpenses: [{ required: true, message: '请输入合计业务支出', trigger: 'blur' }],
        purchaseQuantity: [{ required: true, message: '请输入采购数量', trigger: 'blur' }],
        expectedProcurementTime: [{ required: true, message: '请选择预计采购时间', trigger: 'change' }],
        remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },




    }
  },
  authorize: {              //权限校验注入设置
    deleteRecord: 'delete'  //key为需要注入权限校验的方法名，这里为 deleteRecord 方法；值为需要校验的操作权限，这里为 delete 操作权限
  },
  mounted() {
    this.getData()
    this.getCustList();
    this.getUserList();//销售列表
  },
  // beforeCreate() {
  //   this.form = this.$form.createForm(this, { name: 'validate_other' });
  // },
  computed: {
   
    userPermissions() {
        return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    // 销售列表
    getUserList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
        if (res.data.code == 200) {
          this.userData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 客户列表
    getCustList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list/all', 'get').then(res => {
        if (res.data.code == 200) {
          this.customerData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 成交
    progDeal(record) {
      this.dealVisible = true;
      this.dealItem = record;
    },
    handleDeal() {
      if (this.dealStatus.length == 0) {
        this.$message.warning('请先选择成交状态！')
        return;
      }
      let params = {
        id: this.dealItem.id,
        dealStatus: this.dealStatus
      }
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/deal', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('状态更改成功！')
          this.dealVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 产品
    details(id) {
      this.$router.push({
        path: '/sfa_sales_programs/query_form',
        query: { id: id }
      });
    },
    // 选择导出项
    selectExportChange(e) {
      this.exportSelectArr = e;
    },
    // 导出
    exportChange(e) {
      this.exportIds = [];
      this.exportIds.push(e);
      this.exportVisible = true;
    },
    // 导出确认 
    handleExport() {
      if (this.exportSelectArr.length == 0) {
        this.$message.warning('请先勾选导出信息栏目！')
        return false;
      }
      let arrangeList = [];
      this.exportSelectArr.forEach(item => {
        arrangeList.push(this.exportSelect[item])
      })
      let params = {
        programmeIdList: this.exportIds,
        arrangeList: arrangeList,
        remarks: ""
      }
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/export', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功！')
          this.exportVisible = false;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handleEditCanel() {
      this.EditVisible = false
    },
    handleEdit(e) {
      let url = process.env.VUE_APP_API_BASE_URL + 'sfa/programme/' + this.editObj.id
      let message = '修改成功！'
      e.preventDefault()
      this.$refs.editRuleForm.validate(valid => {
        if (valid) {
          request(url, 'post', this.editObj).then(res => {
            if (res.data.code == 200) {
              this.$message.success(message)
              this.EditVisible = false;
              this.getData()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCopy(e) {
      e.preventDefault()
      this.$refs.copyRuleForm.validate(valid => {
        if (valid) {
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/copyProgramme', 'post', this.copyObj).then(res => {
            if (res.data.code == 200) {
              this.$message.success('复制成功！')
              this.copyVisible = false;
              this.getData()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 编辑
    editChange(e) {
      this.editObj = JSON.parse(JSON.stringify(e));
      this.editObj.expectedProcurementTime = this.formatDate(e.expectedProcurementTime)
      this.EditVisible = true;
    },
    // 打开复制
    copyProg(e) {
      this.copyObj = JSON.parse(JSON.stringify(e));
      this.copyObj.expectedProcurementTime = ''
      this.copyObj.customerId = []
      this.copyVisible = true;
    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    // 采购日期
    creDateChange(date, dateString) {
      this.form.expectedProcurementTime = dateString
    },
    editDateChange(date, dateString) {
      this.editObj.expectedProcurementTime = dateString
    },
    copyDateChange(date, dateString) {
      this.copyObj.expectedProcurementTime = dateString
    },
    // 选择日期
    dateChange(date, dateString) {
      console.log(date, dateString);
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date
    },
    handleOk(e) {
      e.preventDefault()
      let params = JSON.parse(JSON.stringify(this.form));
      let nameArr = this.customerData;
      for (let i = 0; i < nameArr.length; i++) {
        if (nameArr[i].name == params.customerId) {
          params.customerId = nameArr[i].id
        }
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/save', 'post', params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功！')
              this.visible = false;
              this.getData()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    ToDetail(e) {
      e.preventDefault()
      let params = JSON.parse(JSON.stringify(this.form));
      let nameArr = this.customerData;
      for (let i = 0; i < nameArr.length; i++) {
        if (nameArr[i].name == params.customerId) {
          params.customerId = nameArr[i].id
        }
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$message.loading()
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/save', 'post', params).then(res => {
            if (res.data.code == 200) {
              this.$message.destroy()
              this.$message.success('添加成功！')
              this.visible = false;
              this.getData();
              setTimeout(() => {
                this.$router.push({
                  path: '/sfa_sales_programs/query_form',
                  query: { id: res.data.data }
                });
              }, 1000)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
        // staffId:'',
        // dealStatus: '',//成交状态
        // type: '',//
      }
      this.dateValue = ''
      this.getData()
    },
    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },

    // 获取列表 
    getData() {
      this.tabLoading = true;
      let params = JSON.parse(JSON.stringify(this.queryData));
      // this.$message.loading();
      let idArr = params.customerIdList;
      let nameArr = this.customerData;
      if (params.customerIdList.length > 0) {
        for (let i = 0; i < idArr.length; i++) {
          for (let j = 0; j < nameArr.length; j++) {
            if (idArr[i] == nameArr[j].name) {
              idArr[i] = nameArr[j].id
            }
          }
        }
      }
      params.customerIdList = idArr;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/list', 'post', params).then(res => {
        // this.$message.destroy();
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;

          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/' + e.id, 'delete').then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.EditVisible = false;
          this.getData()
        }
      })
    },


    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    remove() {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      this.$message.info('您清空了勾选的所有行')
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}



@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}
</style>
